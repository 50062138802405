<template>
  <div id="app">
    <div class="container">
      <a
        class="discord"
        href="https://discord.gg/7YVaRwrdEt"
      >
        <div class="discord-symbol" />
      </a>
      <div class="home">
        <div class="timer" />
        <div
          class="flip-card"
          :class="{flipped: showLore}"
        >
          <div class="flip-card-inner">
            <div
              class="flip-card-front"
              @click="showTheLore"
            >
              <img src="@/assets/new/frame.png">
              <video
                v-if="minted && showExplosion"
                muted
                autoplay
                loop
                nocontrols
                src="@/assets/new/boom.mp4"
              />
              <video
                v-else
                muted
                autoplay
                loop
                nocontrols
                src="@/assets/new/clouds2.mp4"
              />
            </div>
            <div class="flip-card-back">
              <div class="lore-display">
                $ RPUNK>
                <span
                  class="type"
                  style="--n: 533;"
                  v-html="loreChapters[currentLoreChapter]"
                  :class="{visible: showLore}"
                />
                <button
                  class="previous-lore"
                  style="z-index: 10000000;"
                  @click="showPreviousPageOrHide()"
                />
                <button
                  class="more-lore"
                  style="z-index: 10000000;"
                  @click="showNextPage()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="control-board">
          <button
            :disabled="!activated"
            :class="`big-button ${bigButtonPressed ? 'pressed' : ''}`"
            @click="buy"
          />

          <div
            v-if="this.punksLeft > -1"
            class="counter-numbers tooltip"
          >
            {{ 3000 - this.punksLeft - 1 || '' }}
            <span class="tooltiptext">
              This is how many radioactive punks have been minted out of 3000 total!
            </span>
          </div>
          <div
            v-else
            class="counter-numbers tooltip blank"
          >
            XXXX
            <span class="tooltiptext">
              Sign in to see how many punks are left
            </span>
          </div>


          <div :class="`flashing-lights ${minting ? 'show' : ''}`" />
          <div class="status-display">
            <span
              class="status"
              v-html="status"
            />
          </div>
          <a
            target="_blank"
            href="https://etherscan.io/address/0x073ca28e04719c05a5a48c1d992091b4075a0f84#code"
            class="face-glow"
          />
          <a
            target="_blank"
            href="https://opensea.io/collection/radioactive-punks"
            class="rocket-glow"
          />
          <div class="metamask-tape" />
          <div class="connect-button-bg">
            <button
              :class="`connect-button ${connectButtonPressed ? 'pressed' : ''}`"
              @click="connect"
              :disabled="minting || connectButtonPressed"
            >
              Log In
            </button>
          </div>
          <div class="amount-selector">
            <input
              type="radio"
              name="amount"
              id="amount-1"
              value="1"
              v-model="purchaseCount"
            >
            <label
              for="amount-1"
              class="one"
              checked
            >
              <span class="button-bg" />
            </label>

            <input
              type="radio"
              name="amount"
              id="amount-2"
              value="2"
              v-model="purchaseCount"
            >
            <label
              for="amount-2"
              class="two"
            >
              <span class="button-bg" />
            </label>

            <input
              type="radio"
              name="amount"
              id="amount-3"
              value="3"
              v-model="purchaseCount"
            >
            <label
              for="amount-3"
              class="three"
            >
              <span class="button-bg" />
            </label>

            <input
              type="radio"
              name="amount"
              id="amount-4"
              value="4"
              v-model="purchaseCount"
            >
            <label
              for="amount-4"
              class="four"
            >
              <span class="button-bg" />
            </label>

            <input
              type="radio"
              name="amount"
              id="amount-5"
              value="5"
              v-model="purchaseCount"
            >
            <label
              for="amount-5"
              class="five"
            >
              <span class="button-bg" />
            </label>
          </div>
          <div class="map-button-bg">
            <a
              class="map-button"
              target="blank"
              href="https://discord.gg/7YVaRwrdEt"
            />
          </div>
          <a
            href="https://twitter.com/pixantle"
            class="pixel-link"
          />
          <a
            href="https://twitter.com/iamwhitelights"
            class="wl-link"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ethers } from 'ethers'
import detectEthereumProvider from '@metamask/detect-provider'
import RADIOACTIVEPUNKS from '@/assets/RADIOACTIVEPUNKS.json'

const PRICE = 25000000000000000

export default {
  name: 'PunkGenerator',
  props: {
    msg: String
  },
  data () {
    return {
      // core vars
      loggedIn: false,
      accountAddress: '',
      signature: '',
      provider: undefined,
      contract: undefined,
      activated: false,
      purchaseCount: 1,
      v: undefined,
      r: undefined,
      s: undefined,
      browserChain: undefined,
      // ux vars
      minting: false,
      minted: false,
      punksLeft: 'XXXX',
      status: '<strong>RADIOACTIVE PUNKS</strong><br>1614 PUNKS SURVIVED',
      bigButtonPressed: false,
      connectButtonPressed: false,
      showExplosion: false,
      showLore: false,
      timeXZLeft: '',
      timer: undefined,
      currentLoreChapter: 0,
      loreChapters: [
        'OCTOBER 2021: The lab is in a state of utter meltdown, and [REDACTED] are dispatching the Evacutation Team as we speak. [REDACTED] has deemed all personnel, test subjects, and materials in the lab invaluable and need immediate recovery. There is a maximum two-week timeline for this mission to be completed successfully before [REDACTED]. The expectation at this time is a cataclysmic event akin to a Chernobyl in each major city. [REDACTED] will prepare fallout shelters and storage facilities for long-term habitation and quarantine.<br><br>Still, it seems that we’re rapidly advancing toward the inevitable dawn of a nuclear winter . . . . . .'
      ]
    }
  },
  computed: {
    ethereum () {
      return window.ethereum
    },
    isDev () {
      const searchParams = new URLSearchParams(window.location.search)
      return searchParams.get('dev') || process.env.NODE_ENV === 'development'
    },
    contractAddress () {
      return this.browserChain === 4
        ? '0xCA1F76cD154f18D01a8255CE41A24cb351749bCa'
        : '0x073Ca28E04719C05a5a48C1d992091b4075A0F84'
    }
  },
  mounted () {
    this.updateTime()
    this.timer = window.setInterval(this.updateTime, 1000)
  },
  methods: {
    isMetaMaskInstalled () {
      return !!(this.ethereum && this.ethereum.isMetaMask)
    },
    showNextPage () {
      this.currentLoreChapter++
      this.showLore = true
    },
    showPreviousPageOrHide () {
      this.currentLoreChapter--
      if (this.currentLoreChapter < 0) {
        this.showLore = false
      }
    },
    updateTime () {
      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date

      let distance = '1639501200000' - now
      if (this.activationTime) {
        distance = this.activationTime * 1000 - now
      }

      // Time calculations for days, hours, minutes and seconds
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Display the result in the element with id="demo"
      this.timeLeft = hours + 'h ' +
      minutes + 'm ' + seconds + ' S'

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(this.timer)
        this.timeLeft = 'MINT ENGAGED'
      }
    },
    // Handles logging in via signed transaction
    async connect () {
      this.connectButtonPressed = true
      const interval = this.updateStatusWithLoader('<strong>LOGGING IN</strong>')

      const metamask = await detectEthereumProvider({
        mustBeMetaMask: true
      })

      // need MM
      if (metamask) {
        if (!metamask.isConnected()) {
          throw new 'Metamask Connection Error'()
        }

        // set global provider using MM
        this.provider = new ethers.providers.Web3Provider(metamask)

        // find account address
        const accounts = await this.provider.provider.request({
          method: 'eth_requestAccounts'
        })
        this.accountAddress = accounts[0]

        // find current network/chain
        const chain = await this.provider.provider.request({
          method: 'eth_chainId'
        })
        this.browserChain = parseInt(chain)

        try {
          // check if we're on the right network
          if (this.browserChain === 1 || this.browserChain === 4) {
            const domain = [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ]

            const unit = [
              { name: 'logIn', type: 'string' }
            ]

            const domainData = {
              name: 'RADIOACTIVEPUNKS',
              version: '1',
              chainId: this.browserChain,
              verifyingContract: '0xFc9961d08Ef8e04DB145b9fb3d48cf8DbA96116D' // 0x4f33747d7Cb4BDA64778c2415Ef3bBFa234E06cC
            }

            var message = {
              logIn: 'Log In To Radioactive Punks!'
            }

            const data = JSON.stringify({
              types: {
                EIP712Domain: domain,
                Unit: unit
              },
              domain: domainData,
              primaryType: 'Unit',
              message: message
            })

            const signer = ethers.utils.getAddress(this.accountAddress)

            // sign in with signature
            this.signature = await this.provider.provider.request({
              method: 'eth_signTypedData_v4',
              params: [
                signer,
                data
              ],
              from: signer
            })

            this.signature = this.parseSignature(this.signature.substring(2))

            // grab contract instance with signature
            this.contract = new ethers.Contract(
              this.contractAddress,
              RADIOACTIVEPUNKS.abi,
              this.provider
            )
            this.contract = this.contract.connect(this.provider.getSigner())
            this.activated = await this.contract.activated()
            this.punksLeft = await this.contract.PUNKS_LEFT()
            this.activationTime = await this.contract.activationTime()
            this.activationTime = this.activationTime.toNumber()

            // update UX
            this.loggedIn = !!this.signature

            if (!this.activated) {
              this.status = '<strong>MINT COMPLETED</strong><br>1614 PUNKS LIVE'
            } else if (this.punksLeft < 1) {
              this.status = '<strong>Logged In</strong><br>ALL SOLD OUT'
            } else {
              this.status = '<strong>Logged In</strong><br>Hit the button'
            }
          } else {
            this.status = 'Switching network...'
            await this.provider.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{
                chainId: '0x1'
              }]
            })
            // sleep for half second otherwise MM UX freaks out
            await new Promise(resolve => setTimeout(resolve, 500))
            await this.connect()
          }
        } catch (e) {
          console.warn('Rejected log in')
          console.warn(e)

          if (e.message.indexOf('User denied message signature') >= 0) {
            this.status = '<strong>Log In Rejected</strong><br>Please Try Again'
          } else {
            // Update the UX
            this.status = 'Log In Failed :('
          }
        }
      }

      window.clearInterval(interval)

      this.connectButtonPressed = false
    },

    updateStatusWithLoader (text) {
      this.status = `${text}<br>•••••`
      return window.setInterval(() => {
        const dots = this.status.split('•').length - 1
        switch (dots) {
        case 1: {
          this.status = `${text}<br>••`
          break
        }
        case 2: {
          this.status = `${text}<br>•••`
          break
        }
        case 3: {
          this.status = `${text}<br>••••`
          break
        }
        case 4: {
          this.status = `${text}<br>•••••`
          break
        }
        case 5: {
          this.status = `${text}<br>••••••`
          break
        }
        case 6: {
          this.status = `${text}<br>•••••••`
          break
        }
        case 7: {
          this.status = `${text}<br>••••••••`
          break
        }
        case 8: {
          this.status = `${text}<br>•`
          break
        }
        }
      }, 300)
    },

    getURLForTokenId (tokenId) {
      if (this.isDev) {
        return `https://testnets.opensea.io/assets/${this.contractAddress}/${tokenId}`
      } else {
        // TODO: Update this
        return `https://opensea.io/assets/${this.contractAddress}/${tokenId}`
      }
    },

    getTransactionURL (hash) {
      if (this.isDev) {
        return `https://rinkeby.etherscan.io/tx/${hash}`
      } else {
        // TODO: Update this
        return `https://etherscan.io/tx/${hash}`
      }
    },

    async buy () {
      this.bigButtonPressed = true
      this.minting = true

      let interval = 0
      try {
        // reconnect if they haven't yet
        if (!this.loggedIn) {
          await this.connect()
        }

        // buy the amount they selected
        if (this.purchaseCount === 1) {
          interval = this.updateStatusWithLoader('Minting 1 Punk...')
        } else {
          interval = this.updateStatusWithLoader(`Minting ${this.purchaseCount} Punks...`)
        }
        const totalPrice = PRICE * this.purchaseCount + ''
        const gasEstimate = await this.contract.estimateGas['mintPunks(uint256,uint8,bytes32,bytes32)'](
          this.purchaseCount,
          this.signature.v,
          this.signature.r,
          this.signature.s,
          { value: totalPrice }
        )
        const integerGasLimitEstimate = parseInt(gasEstimate)
        const betterGasLimitEstimate = Math.floor(integerGasLimitEstimate * 1.2)
        const response = await this.contract['mintPunks(uint256,uint8,bytes32,bytes32)'](
          this.purchaseCount,
          this.signature.v,
          this.signature.r,
          this.signature.s,
          {
            value: totalPrice,
            gasLimit: betterGasLimitEstimate
          }
        )

        window.clearInterval(interval)

        // show loading status while confirming on chain
        interval = this.updateStatusWithLoader(`Confirming <a href="${this.getTransactionURL(response.hash)}" target="_blank">on chain</a>`)
        const confirmedResponse = await response.wait([1])
        window.clearInterval(interval)

        // show all punks they minted
        this.status = this.purchaseCount > 1
          ? '<strong>Minted Punks</strong>'
          : '<strong>Minted Punk</strong>'
        confirmedResponse.events.forEach((event) => {
          const id = parseInt(event.args.tokenId._hex)
          const url = this.getURLForTokenId(id)
          this.status += ` <a href="${url}" target="_blank">#${parseInt(event.args.tokenId._hex)}</a>`
        })

        this.minted = true
        this.showExplosion = true
        window.setTimeout(() => {
          this.showExplosion = false
        }, 3500)
        this.punksLeft = await this.contract.PUNKS_LEFT()
        this.activated = await this.contract.activated()
      } catch (e) {
        window.clearInterval(interval)
        if (!this.signature) {
          this.status = '<strong>Log In Rejected</strong><br>Please try again'
        } else {
          if (e.code === 'CALL_EXCEPTION') {
            const url = this.getTransactionURL(e.transactionHash)
            this.status = `<strong>Minting <a href="${url}" target="_blank">Failed</a></strong><br>Please try again`
          } else if (e.code === 'TRANSACTION_REPLACED') {
            if (e.reason === 'cancelled') {
              this.status = '<strong>Mint Cancelled</strong><br>Hit the button'
            } else if (e.reason === 'repriced' || e.reason === 'replaced') {
              const url = this.getTransactionURL(e.replacement.transactionHash)
              this.status = `<strong>Mint Sped Up</strong><br>New Mint TX<a href="${url}" target="_blank">Here</a>`
            }
          } else if (e.message.includes('Only 10 for first 10m')) {
            this.status = '<strong>Mint Rejected</strong><br>Only 10 for first 10 mins'
          } else if (e.message.includes('Owns max amount')) {
            this.status = '<strong>Mint Rejected</strong><br>Only 100 mints per owner'
          } else if (e.message.includes('denied transaction signature')) {
            this.status = '<strong>Mint Rejected</strong><br>Hit the button'
          } else if (e.message.includes('No cheaters!')) {
            this.tryLedgerBuy()
          } else {
            // check for various failing mint states of wait()
            this.status = 'Minting Failure<br>Use A Hot Wallet'
          }
        }
      }

      // Update UX no matter what happens
      this.minting = false
      this.bigButtonPressed = false
    },

    async tryLedgerBuy () {
      this.bigButtonPressed = true
      this.minting = true

      let interval = 0
      try {
        // reconnect if they haven't yet
        if (!this.loggedIn) {
          await this.connect()
        }

        // buy the amount they selected
        if (this.purchaseCount === 1) {
          interval = this.updateStatusWithLoader('Minting 1 Punk...')
        } else {
          interval = this.updateStatusWithLoader(`Minting ${this.purchaseCount} Punks...`)
        }
        const totalPrice = PRICE * this.purchaseCount + ''
        const gasEstimate = await this.contract.estimateGas['mintPunks(uint256,uint8,bytes32,bytes32)'](
          this.purchaseCount,
          this.signature.v + 27, // ledger wtf
          this.signature.r,
          this.signature.s,
          { value: totalPrice }
        )
        const integerGasLimitEstimate = parseInt(gasEstimate)
        const betterGasLimitEstimate = Math.floor(integerGasLimitEstimate * 1.2)
        const response = await this.contract['mintPunks(uint256,uint8,bytes32,bytes32)'](
          this.purchaseCount,
          this.signature.v + 27, // ledger wtf
          this.signature.r,
          this.signature.s,
          {
            value: totalPrice,
            gasLimit: betterGasLimitEstimate
          }
        )

        window.clearInterval(interval)

        // show loading status while confirming on chain
        interval = this.updateStatusWithLoader(`Confirming <a href="${this.getTransactionURL(response.hash)}" target="_blank">on chain</a>`)
        const confirmedResponse = await response.wait([1])
        window.clearInterval(interval)

        // show all punks they minted
        this.status = this.purchaseCount > 1
          ? '<strong>Minted Punks</strong>'
          : '<strong>Minted Punk</strong>'
        confirmedResponse.events.forEach((event) => {
          const id = parseInt(event.args.tokenId._hex)
          const url = this.getURLForTokenId(id)
          this.status += ` <a href="${url}" target="_blank">#${parseInt(event.args.tokenId._hex)}</a>`
        })

        this.minted = true
        this.showExplosion = true
        window.setTimeout(() => {
          this.showExplosion = false
        }, 3500)
        this.punksLeft = await this.contract.PUNKS_LEFT()
        this.activated = await this.contract.activated()
      } catch (e) {
        window.clearInterval(interval)
        console.log(e.code)
        if (!this.signature) {
          this.status = '<strong>Log In Rejected</strong><br>Please try again'
        } else {
          if (e.code === 'CALL_EXCEPTION') {
            const url = this.getTransactionURL(e.transactionHash)
            this.status = `<strong>Minting <a href="${url}" target="_blank">Failed</a></strong><br>Please try again`
          } else if (e.code === 'TRANSACTION_REPLACED') {
            if (e.reason === 'cancelled') {
              this.status = '<strong>Mint Cancelled</strong><br>Hit the button'
            } else if (e.reason === 'repriced' || e.reason === 'replaced') {
              const url = this.getTransactionURL(e.replacement.transactionHash)
              this.status = `<strong>Mint Sped Up</strong><br>New Mint TX<a href="${url}" target="_blank">Here</a>`
            }
          } else if (e.message.includes('Only 10 for first 10m')) {
            this.status = '<strong>Mint Rejected</strong><br>Only 10 for first 10 mins'
          } else if (e.message.includes('Owns max amount')) {
            this.status = '<strong>Mint Rejected</strong><br>Only 100 mints per owner'
          } else if (e.message.includes('denied transaction signature')) {
            this.status = '<strong>Mint Rejected</strong><br>Hit the button'
          } else if (e.message.includes('No cheaters!')) {
            this.status = 'Trezor/Ledger Issue<br>Try Hot Wallet'
          } else {
            // check for various failing mint states of wait()
            this.status = 'Minting Failure<br>Use A Hot Wallet'
          }
        }
      }

      // Update UX no matter what happens
      this.minting = false
      this.bigButtonPressed = false
    },

    parseSignature (signature) {
      var r = signature.substring(0, 64)
      var s = signature.substring(64, 128)
      var v = signature.substring(128, 130)

      return {
        r: '0x' + r,
        s: '0x' + s,
        v: parseInt(v, 16)
      }
    },

    showTheLore () {
      this.showLore = true
    },

    hideTheLore () {
      this.showLore = false
    },

    async getAllOwners () {
      const holders = []
      for (let i = 0; i <= 1607; i++) {
        console.log(`owner index ${i}`)
        try {
          const x = await this.contract['tokenByIndex(uint256)'](i)
          const tokenId = parseInt(x._hex)
          const ownerAddress = await this.contract['ownerOf(uint256)'](tokenId)
          holders.push(ownerAddress)
        } catch (error) {
          console.log('the end')
        }
      }
      const uniqueHolders = [...new Set(holders)]
      console.log(JSON.stringify(uniqueHolders))
    },

    async getAllTokens () {
      const tokens = []
      for (let i = 0; i <= 1607; i++) {
        console.log(`owner index ${i}`)
        try {
          const x = await this.contract['tokenByIndex(uint256)'](i)
          const tokenId = parseInt(x._hex)
          tokens.push(tokenId)
        } catch (error) {
          console.log('the end')
        }
      }

      console.log(JSON.stringify(tokens))
    }
  }
}
</script>

<style scoped lang="scss">

#app {
  background: #1f221d;
}

@font-face {
  font-family: 'Small Pixel';
  src:
    url('~@/assets/fonts/Small-Pixel.woff') format('woff'),
    url('~@/assets/fonts/Small-Pixel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src:
    url('~@/assets/fonts/DS-Digital-BoldItalic.woff2') format('woff2'),
    url('~@/assets/fonts/DS-Digital-BoldItalic.woff') format('woff'),
    url('~@/assets/fonts/DS-Digital-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src:
    url('~@/assets/fonts/DS-Digital.woff2') format('woff2'),
    url('~@/assets/fonts/DS-Digital.woff') format('woff'),
    url('~@/assets/fonts/DS-Digital.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src:
    url('~@/assets/fonts/DS-Digital-Italic.woff2') format('woff2'),
    url('~@/assets/fonts/DS-Digital-Italic.woff') format('woff'),
    url('~@/assets/fonts/DS-Digital-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src:
    url('~@/assets/fonts/DS-Digital-Bold.woff2') format('woff2'),
    url('~@/assets/fonts/DS-Digital-Bold.woff') format('woff'),
    url('~@/assets/fonts/DS-Digital-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.home {
  padding: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  image-rendering: pixelated;
  transform: scale(0.8);
  position: absolute;
  top: -120px;
  left: 0;
  right: 0;

  @media screen and (max-width: 800px) {
    transform: scale(0.7);
  }

  @media screen and (max-width: 650px) {
    transform: scale(0.6);
    top: -250px;
  }

  @media screen and (max-width: 500px) {
    transform: scale(0.45);
    top: -300px;
  }
}

.control-board {
  position: relative;
  width: 843px;
  height: 639px;
  background: url('~@/assets/new/controlboard.png') no-repeat center center;
  background-size: contain;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    transform: scale(0.7);
    transform: translateX(-10%);
  }

  @media screen and (max-width: 650px) {
    transform: scale(0.6);
    transform: translateX(-15%);
  }

  @media screen and (max-width: 500px) {
    transform: scale(0.5);
    transform: translateX(-30%);
  }
}

.big-button {
  position: absolute;
  width: 99px;
  height: 96px;
  background: url('~@/assets/new/button-unpressed.png') no-repeat bottom center;
  margin: auto;
  border: none;
  outline: none;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 244px;

  &.pressed {
    background: url('~@/assets/new/button-pressed.png') no-repeat bottom center;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.7;
    background: url('~@/assets/new/button-pressed.png') no-repeat bottom center;
  }
}

.status-display {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 274px;
  height: 80px;
  background: url('~@/assets/new/status-display.png') no-repeat center center;
  background-size: contain;
  margin: auto;
  top: 32px;
  right: 0;
  left: 5px;
  font-family: 'DS-Digital', monospace;
  font-size: 22px;

  .status {
    position: relative;
    bottom: 2px;
    padding: 0 20px;
  }

  a {
    color: #2c3e50;
    transition: color 0.2s ease;

    &:hover {
      color: gray;
    }
  }
}

.counter-numbers {
  position: absolute;
  font-family: 'Small Pixel', monospace;
  top: 170px;
  left: 76px;
  font-size: 18px;
  letter-spacing: 8px;

  &.blank {
    letter-spacing: 6.5px;
  }
}

.flashing-lights {
  position: absolute;
  display: none;
  top: 36px;
  left: 33px;
  width: 234px;
  height: 36px;
  background: url('~@/assets/new/glowinglights.png') no-repeat center center;
  background-size: contain;
  animation: flash 0.3s steps(2, end) infinite;

  &.show {
    display: block;
  }
}

.metamask-tape {
  position: absolute;
  bottom: 350px;
  left: 33px;
  height: 36px;
  width: 171px;
  background: url('~@/assets/new/metamask.png') no-repeat center center;
  background-size: contain;
}

.face-glow {
  position: absolute;
  top: 23px;
  right: 32px;
  height: 150px;
  width: 135px;
  background: url('~@/assets/new/face-glow.png') no-repeat center center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: 0.3;
  }
}

.rocket-glow {
  position: absolute;
  top: 195px;
  right: 24px;
  height: 105px;
  width: 140px;
  background: url('~@/assets/new/face-glow.png') no-repeat center center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: 0.3;
  }
}

.amount-selector {
  position: absolute;
  margin: auto;
  width: 290px;
  bottom: 228px;
  left: 18px;
  right: 0;

  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  input[type='radio']:checked + label {
    span {
      background: grey;
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0.15;
    }

    &.one {
      background: url('~@/assets/new/1p.png') no-repeat center center;
      background-size: contain;
    }

    &.two {
      background: url('~@/assets/new/2p.png') no-repeat center center;
      background-size: contain;
    }

    &.three {
      background: url('~@/assets/new/3p.png') no-repeat center center;
      background-size: contain;
    }

    &.four {
      background: url('~@/assets/new/4p.png') no-repeat center center;
      background-size: contain;
    }

    &.five {
      background: url('~@/assets/new/5p.png') no-repeat center center;
      background-size: contain;
    }
  }

  label {
    display: inline-block;
    background-color: #ddd;
    width: 36px;
    height: 45px;
    margin: 0 7px 0 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.3;
    }

    &.one {
      background: url('~@/assets/new/1u.png') no-repeat center center;
      background-size: contain;
    }

    &.two {
      background: url('~@/assets/new/2u.png') no-repeat center center;
      background-size: contain;
    }

    &.three {
      background: url('~@/assets/new/3u.png') no-repeat center center;
      background-size: contain;
    }

    &.four {
      background: url('~@/assets/new/4u.png') no-repeat center center;
      background-size: contain;
    }

    &.five {
      background: url('~@/assets/new/5u.png') no-repeat center center;
      background-size: contain;
    }
  }
}

.pixel-link {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 130px;

  &:hover {
    cursor: pointer;
    opacity: 0.3;
  }
}

.wl-link {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
}

.connect-button-bg {
  position: relative;
  width: 138px;
  height: 126px;
  background: url('~@/assets/new/connect-button-bg.png') no-repeat center center;
  background-size: contain;
  top: 300px;
  left: 50px;

  > button {
    position: relative;
    background: url('~@/assets/new/connect-button-u.png') no-repeat center center;
    background-size: contain;
    width: 84px;
    height: 33px;
    outline: 0;
    border: 0;
    top: 70px;
    left: 0;
    font-family: 'Small Pixel', monospace;
    padding-bottom: 5px;
    font-size: 18px;

    &:hover {
      cursor: pointer;
      border: 1px dashed green;
    }

    &.pressed {
      padding-top: 7px;
      opacity: 1;
      background: url('~@/assets/new/connect-button-p.png') no-repeat bottom center;
    }

    &:disabled,
    &[disabled] {
      background: url('~@/assets/new/connect-button-p.png') no-repeat bottom center;
    }
  }
}

.map-button-bg {
  position: absolute;
  width: 102px;
  height: 81px;
  background: url('~@/assets/new/map-bg.png') no-repeat center center;
  background-size: contain;
  bottom: 220px;
  right: 75px;

  a {
    position: relative;
    display: block;
    width: 84px;
    height: 33px;
    top: 25px;
    left: 10px;
    outline: 0;
    border: 0;
    font-family: 'Small Pixel', monospace;
    font-size: 18px;
    background: url('~@/assets/new/map-u.png') no-repeat center center;
    background-size: contain;

    &:hover {
      opacity: 0.6;
    }
  }
}

.type {
  position: relative;
  width: 100%;
  color: #0000;
  text-align: left;
  background:
    linear-gradient(-90deg, #2c3e50 5px, #0000 0) 10px 0,
    linear-gradient(#2c3e50 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;

  &.visible {
    animation:
      b 0.7s infinite steps(1),
      t calc(var(--n) * 0.015s) steps(var(--n)) forwards;
  }
}

@keyframes t {
  from { background-size: 0 200%; }
}

@keyframes b {
  50% { background-position: 0 -100%, 0 0; }
}

body,
html {
  background: rgba(32, 34, 29, 1);
}

@keyframes flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.flip-card {
  margin: 40px auto 40px;
  width: 100%;
  height: 0;
  padding-bottom: 77%;

  @media screen and (min-width: 640px) {
    width: 552px;
    height: 426px;
    padding: 0;
  }

  max-width: 552px;
  max-height: 426px;
  position: relative;
  outline: 2px solid transparent;
  transition: transform 1s ease, outline 0.1s ease;
  perspective: 1500px;
  font-family: 'DS-Digital', monospace;
  font-size: 20px;
  text-align: left;

  video,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  img {
    z-index: 100;
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card .flip-card-inner {
  transform: rotateY(0deg);
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: left;
  border: 2px solid transparent;
  transition: border 0.1s ease;

  &:hover {
    border: 2px solid #2af21b;
  }
}

.flip-card-front {
  background-size: contain;
}

.timer {
  font-family: 'DS-Digital', monospace;
  font-size: 24px;
  font-weight: 800;
  color: #78ff00;
}

/* Style the back side */
.flip-card-back {
  background: url('~@/assets/new/bigscreen.png') no-repeat center center;
  background-size: contain;
  color: white;
  transform: rotateY(180deg);
}

.lore-display {
  width: 100%;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
}

.minting-closed {
  font-size: 45px;
  transition: color 0.1s ease;
  color: #78ff00;
  margin: 10px 0;
  display: block;

  &:hover {
    color: #78ff00;
  }
}

.rarity-tool {
  font-family: 'Small Pixel', monospace;
  display: block;
  text-decoration: none;
  margin: 15px 0;
  transition: color 0.1s ease;
  color: #78ff00;

  &:hover {
    color: red;
  }

  @media screen and (max-width: 800px) {
    font-size: 22px;
  }
}

.discord {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.discord-symbol {
  background: url('~@/assets/discord.svg') no-repeat center center;
  background-size: contain;
  width: 35px;
  height: 30px;
  color: grey;

  &:hover {
    color: white;
  }
}

.tooltip {
  .tooltip-indicator {
    font-size: 14px;
    color: grey;
    transition: color 0.2s ease;

    &:hover {
      color: black;
    }
  }

  .tooltiptext {
    position: absolute;
    visibility: hidden;
    width: 200px;
    top: -50px;
    left: 100px;
    padding: 20px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    border-radius: 6px;
    background-color: black;
    font-weight: 700;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .tooltiptext::after {
    content: '';
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid black;
    position: absolute;
    transform: rotate(90deg);
    top: 25px;
    left: -20px;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
      cursor: pointer;
    }
  }
}

.more-lore {
  position: absolute;
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  bottom: 35px;
  right: 30px;
  z-index: 100000;
  background: url('~@/assets/new/screen-button-right.png') no-repeat center center;
  background-size: contain;

  &:hover {
    opacity: 0.5;
  }
}

.previous-lore {
  position: absolute;
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  bottom: 35px;
  right: 75px;
  z-index: 100000;
  background: url('~@/assets/new/screen-button-left.png') no-repeat center center;
  background-size: contain;

  &:hover {
    opacity: 0.5;
  }
}

</style>
