<template>
  <div
    id="transaction"
    class="content noscroll draggable-window text-center"
    style="width: 20%;"
    data-window-icon="img/controls.png"
    data-window-name="Transaction"
  >
    <h1 class="titlebar noright">
      <span>Transaction</span>
    </h1>
    <div class="inner">
      <div id="transaction-inner">
        <span v-if="pending && pendingTxURL">
          Your <a
            :href="pendingTxURL"
            target="_blank"
          >transaction</a> is pending...<br><br>
          Please do not reload or close this page yet!
          <LoadingSpinner />
        </span>
        <span v-else-if="pending && !pendingTxURL">
          Please accept your transaction in Metamask.
          <LoadingSpinner />

        </span>
        <span
          v-else
          v-html="finishedTxString"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'TransactionWindow',
  props: {
    pending: Boolean,
    minted: Boolean,
    pendingTxURL: String,
    finishedTxString: String
  },
  components: {
    LoadingSpinner: LoadingSpinner
  },
  mounted () {
    jQuery('.draggable-window').draggable({
      handle: 'h1.titlebar',
      containment: jQuery('.desktop'),
      start: (event) => {
        jQuery('.content').css('z-index', '1100')
        jQuery(event.target).css('z-index', '1200')
        jQuery('#sound_move_stop').trigger('play')
      },
      stop: (event, ui) => {
        jQuery('#sound_move_stop').trigger('play')
      }
    })
  }
}
</script>
