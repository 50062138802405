<template>
  <div id="loader" />
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>


<style scoped lang="scss">
#loader {
  animation: spin 1s linear infinite;
  height: 10px;
  width: 10px;
  transform: scale(0.25);
  position: relative;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  6.25% {
    box-shadow:
      0 -30px transparent,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  12.5% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  18.75% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  25% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  31.25% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  37.5% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px #000,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px transparent,
      -10px -30px transparent;
  }

  43.75% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px #000,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px transparent;
  }

  50% {
    box-shadow:
      0 -30px transparent,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px #000,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  56.25% {
    box-shadow:
      0 -30px #000,
      10px -30px transparent,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px #000,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  62.5% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px transparent,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px #000,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  68.75% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px transparent,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px #000,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  75% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 transparent,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 #000,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  81.25% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px transparent,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px #000,
      -20px -20px #000,
      -10px -30px #000;
  }

  87.5% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px transparent,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px #000,
      -10px -30px #000;
  }

  93.75% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px transparent,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px #000;
  }

  100% {
    box-shadow:
      0 -30px #000,
      10px -30px #000,
      20px -20px #000,
      30px -10px #000,
      30px 0 #000,
      30px 10px #000,
      20px 20px #000,
      10px 30px #000,
      0 30px transparent,
      -10px 30px transparent,
      -20px 20px transparent,
      -30px 10px transparent,
      -30px 0 transparent,
      -30px -10px transparent,
      -20px -20px transparent,
      -10px -30px transparent;
  }
}
</style>
